import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import './styleUtils/welcomeStyles.css';
import { AppContext } from './Context';
import ExistingProfileCard from './componentUtils/existingProfileCard';
import { getLoggedInUserId } from '../../actions/authAction';
import { getMatrimonialProfiles } from '../../actions/matrimonialAction';
import { toast } from 'react-toastify';
import LoadingSpinner from '../loader';
import { InputGroup } from '@paljs/ui/Input';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ExtractDataFromProfile } from './componentUtils/formatDisplayName';
import { navigate } from 'gatsby';
import ProfilesList from './profilesList';

// // Define interface for profile properties
// interface Profile {
//   _id: string;
//   name: string;
//   age: string;
//   occupation: string;
//   // Add more properties as needed
// }

const MatrimonyProfilesList = () => {
  const [loading, setLoading] = useState(false);
  const { handleNextPage, activePage } = useContext(AppContext);
  const [profiles, setProfiles] = useState([]); // Initialize as empty array

  const loggedInUserId = getLoggedInUserId();
  //   const buttons = [
  //     {
  //       buttonName: '+ Add Profile',
  //       wrapperStyle: footerButtonStyleContained,
  //       varient: 'contained',
  //       buttonStyle: materialUIButtonStyle,
  //       disabled: false,
  //       onClick: handleNextPage,
  //     },
  //   ];

  const getProfiles = async () => {
    try {
      setLoading(true);
      const response = await getMatrimonialProfiles({ node_id: loggedInUserId });
      setLoading(false);
      if (response?.status) {
        toast.success(`Matrimony profiles fetch succssfully`);

        setProfiles(response?.profiles || []);
      } else {
        toast.error(`Error: Failed to fetch matrimonial profiles, ${response.error_message}`);
        console.log('Error: Failed to fetch matrimonial profiles');
      }
    } catch (error) {
      console.error('Error: ', error);
      toast.error(`Error: ${error}`);
    }
  };
  useEffect(() => {
    getProfiles();
  }, [loggedInUserId]);

  const handleOnClick = () => {
    handleNextPage();
    // const nextPage = activePage + 1;
    // navigate(`/matrimonials/createMatrimony`);
  };

  return (
    <>
      <ProfilesList
        title={'आपके परिवार में बनाई गई प्रोफाइल्स'}
        handleShowAllProfiles={getProfiles}
        loading={loading}
        profiles={profiles}
      />
    </>
  );
};

export default MatrimonyProfilesList;
