import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import UserList from './usersOfVillage';
import SelectVillage from '../family-tree/select-village';
import { navigate } from 'gatsby';
import EditIcon from '@mui/icons-material/Edit';
import { moveVillage } from '../../helpers/utils';
import VillageDetails from './villageDetails';

const filterVillage = () => {
  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url);
  const villageNamefromURL = params.get('name');
  const [name, setName] = useState(villageNamefromURL);

  const updateURL = () => {
    console.log('update url called');
    const queryParams = new URLSearchParams();
    queryParams.set('name', name);
    navigate(`?${queryParams.toString()}`);
  };
  const postVillageUpdate = (newVillage) => {
    setName(newVillage);
  };
  useEffect(() => {
    updateURL();
  }, [name]);

  return (
    <>
      <div>Search For Village</div>
      <SelectVillage setVillage={setName} village={name} />
      {/* <ReactSearchAutocomplete
        items={rows}
        onSearch={handleOnSearch}
        onHover={handleOnHover}
        onSelect={handleOnSelect}
        onFocus={handleOnFocus}
        autoFocus
        formatResult={formatResult}
        placeholder="Search for village"
      /> */}
      <br />
      <VillageDetails name={name} />
      <div
        style={{
          textAlign: 'center',
          backgroundColor: 'whitesmoke',
          color: 'black',
          padding: '18px',
          fontSize: '25px',
          marginBottom: '10px',
        }}
      >
        {name}
        <EditIcon
          onClick={() => {
            const newVillage = window.prompt(`Enter the new name of ${name}`);
            if (newVillage) {
              const isUpdateUrl = true;
              moveVillage(name, newVillage, postVillageUpdate(newVillage), true);
            }
          }}
          style={{ color: '#000', height: '30px', width: '20px', marginLeft: '5px', cursor: 'pointer' }}
        />
      </div>
      <UserList vName={name} />
    </>
  );
};
export default filterVillage;
