import React, { useContext, useEffect } from 'react';
import { AppContext } from '../Context';

const PreviewCardHeader = (props: any) => {
  const { heading, stepValue, matrimonialId, isEditButton = true } = props;
  // const { handleChangePageVal, handleChangeStepVal, toggleEditMode } = useContext(AppContext);
  const handleEditClick = () => {
    const url = `/matrimonials/stepComponents/stepForm?stepValue=${stepValue}&isEdit=true&matrimonialId=${matrimonialId}`;
    window.open(url, '_blank');
  };
  return (
    <div className="outer-header-previewcard">
      <div className="header-previewcard">{heading}</div>
      {!isEditButton ? (
        ''
      ) : (
        <a
          // className="edit-previewcard"
          // onClick={() => {
          //   handleChangePageVal(2);
          //   handleChangeStepVal(previewStepValue);
          //   toggleEditMode();
          // }}
          style={{ cursor: 'pointer' }}
          onClick={handleEditClick}
        >
          EDIT
        </a>
      )}
    </div>
  );
};

export default PreviewCardHeader;
