import { Button } from '@mui/material';
import { Link } from 'gatsby';

import React, { useState } from 'react';
import UserGivenVillageDetailsEditModal from './UserGivenVilllageDetailsEditModal';

const UserGivenVillageDetails = ({ villageDetails, postUserGivenVillageDetails }) => {
  const [userGivenVillageDetailsEditModal, setUserGivenVillageDetailsEditModal] = useState(false);
  console.log('villagedetails...', villageDetails);
  if (!villageDetails) {
    return null;
  }
  const handleEditClick = () => {
    setUserGivenVillageDetailsEditModal(true);
  };
  const handleOnClose = () => {
    setUserGivenVillageDetailsEditModal(false);
  };

  return (
    <div
      style={{
        width: 'full',
        backgroundColor: 'whitesmoke',
        color: 'black',
        marginBottom: '10px',
        padding: '18px',
        fontSize: '18px',
        borderRadius: '8px',
      }}
    >
      <Button
        size="small"
        style={{
          marginLeft: '150px',
        }}
        onClick={handleEditClick}
      >
        Edit
      </Button>

      {villageDetails?.results.map((item, index) => (
        <div key={index} style={{ marginBottom: '10px' }}>
          <p>
            {item?.pincode && (
              <>
                <strong>PIN:</strong> {item?.pincode}
              </>
            )}
            {item?.district && (
              <>
                {item?.pincode && ', '}
                <strong>ज़िला:</strong>
                {item?.district},
              </>
            )}
            {item?.google_map_url && (
              <>
                {item?.district && ', '}
                <Link href={item?.google_map_url} target="_blank" rel="noopener noreferrer">
                  Map
                </Link>
              </>
            )}
          </p>
          {item?.area_type && (
            <p>
              <strong>Area Type:</strong> {item?.area_type}
            </p>
          )}
          {item?.expected_count && (
            <p>
              <strong>Expected Count:</strong> {item?.expected_count}
            </p>
          )}
          {(item?.ward_gram_panchayat || item?.panchayat_samiti_nagarapalika) && (
            <p>
              {item?.ward_gram_panchayat && (
                <>
                  <strong>Panchayat:</strong> {item?.ward_gram_panchayat}
                </>
              )}
              {item?.panchayat_samiti_nagarapalika && (
                <>
                  {item?.ward_gram_panchayat && ', '}
                  <strong>Nagarapalika:</strong> {item?.panchayat_samiti_nagarapalika}
                </>
              )}
            </p>
          )}
        </div>
      ))}
      {userGivenVillageDetailsEditModal && (
        <UserGivenVillageDetailsEditModal
          postUserGivenVillageDetails={postUserGivenVillageDetails}
          onClose={() => {
            console.log('clode modal clicked');
            setUserGivenVillageDetailsEditModal(false);
          }}
        />
      )}
    </div>
  );
};

export default UserGivenVillageDetails;
