// import { Button } from '@paljs/ui/Button';
import { Button } from '@mui/material';
import React from 'react';

const VillageGoogleMapInfo = ({ villageGoogleMapInfo }) => {
  if (!villageGoogleMapInfo) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: 'whitesmoke',
        color: 'black',
        marginBottom: '10px',
        fontSize: '18px',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        borderRadius: '8px',
      }}
    >
      <Button
        size="small"
        style={{
          marginLeft: '250px',
        }}
        //  onClick={(event) => handleEditClick(event, row)}
      >
        Edit
      </Button>
      <div style={{ marginBottom: '10px' }}>
        <p>
          <strong>Formatted Address:</strong> {villageGoogleMapInfo?.google_place_location?.formatted_address}
        </p>
        <p>
          <strong>Pincode:</strong> {villageGoogleMapInfo?.google_place_location?.pincode}
        </p>
        <p>
          <strong>Latitude:</strong> {villageGoogleMapInfo?.google_place_location?.geometry?.location?.lat}
        </p>
        <p>
          <strong>Longitude:</strong> {villageGoogleMapInfo?.google_place_location?.geometry?.location?.lng}
        </p>
      </div>
    </div>
  );
};

export default VillageGoogleMapInfo;
