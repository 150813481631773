import React, { useState, useEffect, useRef } from 'react';
import { getVillages, updateVillage } from '../../actions/villageAction';
import SearchBar from 'material-ui-search-bar';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import LoadingSpinner from '../loader';
import { navigate, Link } from 'gatsby';
import DataTable from 'react-data-table-component';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Fuse from 'fuse.js';
import Filter from '../../components/filter/village-list-filters';
import items from '../../Layouts/menuItem';
import { verify } from 'crypto';
import differenceBy from 'lodash/differenceBy';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import Export from '../../components/export_csv/export';
import { toast } from 'react-toastify';
import PlacesAutocomplete from '../extra-components/placesAutocomplete';
import { postVillageData } from '../../actions/villageVerifyAction';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import { getLoggedInUser } from '../../actions/authAction';
import { moveVillage } from '../../helpers/utils';

const Villages = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, showLoader] = useState(true);
  const [originalRows, setOriginalRows] = useState([]);
  const [searchRows, setSearchRows] = useState([]);
  const [max_node_count, setMaxCount] = useState(0);
  const [max_install_count, setMaxInstallCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalInstalledCount, setTotalInstalledCount] = useState(0);
  const [totalExpectedCount, setTotalExpectedCount] = useState(0);
  const [editingRow, setEditingRow] = useState(null);
  const [googleLocation, setGoogleLocation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [googlePlacesFilter, setGooglePlacesFilter] = useState('ALL');

  const options = {
    keys: ['name'],
  };

  const fuse = new Fuse(searchRows, options);

  const handleGooglePlacesFilterChange = (event) => {
    setGooglePlacesFilter(event.target.value);
    const filteredRows = originalRows.filter((row) => {
      if (event.target.value === 'YES') {
        return row?.currentGoogleLocation;
      } else if (event.target.value === 'NO') {
        return !row?.currentGoogleLocation;
      }
      return true;
    });
    setRows(filteredRows);
  };

  const handleGooglePlaces = (googleLocation, row) => {
    // console.log('handleGooglePlaces', googleLocation);
    console.log('row', row);
    const data = {
      app_village_name: row?.name,
      district: row?.district,
      longitude: row?.longitude || googleLocation?.longitude,
      latitude: row?.latitude || googleLocation?.latitude,
      panchayat: row?.panchayat,
      tehsil: row?.tehsil,
      village_name: row?.name,
      pincode: row?.pincode || googleLocation?.pincode,
      google_place_location: googleLocation,
      google_map_url: row?.google_map_url || '',
      verifier_name: getLoggedInUser()?.name,
      village_verified_name: row?.name,
    };
    try {
      postVillageData(data);
      // Refresh data after verification
      // villagesList();
    } catch (error) {
      console.error('Error while verifying village:', error);
    }
  };
  const handleEditClick = (event, row) => {
    setEditingRow(row);
    setAnchorEl(event.currentTarget);
  };

  const closePopper = () => {
    setAnchorEl(null);
    setEditingRow(null);
    setGoogleLocation(null); // Reset selected location when closing popper
  };
  const confirmLocation = () => {
    if (googleLocation) {
      handleGooglePlaces(googleLocation, editingRow);
      setEditingRow(null);
      toast.success('Google Location updated successfully');
    } else {
      toast.error('Please select a Google location');
    }
  };
  const tableColumns = [
    {
      name: 'S.No.',
      sortable: false,
      width: '60px',
      cell: (row, index, column, id) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <a target="_blank" href={`/villages/filterVillages?name=${row['name']}`}>
          {row.name}
        </a>
      ),
    },
    {
      name: 'Current Google Location',
      selector: (row) => row?.currentGoogleLocation,
      width: 'full',
      sortable: true,
      cell: (row) => <div>{row?.currentGoogleLocation}</div>,
    },
    {
      name: 'Google Location',
      button: true,
      cell: (row) => (
        <>
          <Button
            id={`button-${row._id}`}
            shape={'Round'}
            size={'Tiny'}
            Status={'Success'}
            onClick={(event) => handleEditClick(event, row)}
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      name: 'Village Name, Panchayat, Tehsil, District',
      selector: (row) => `${row.village_name}, ${row.panchayat}, ${row.tehsil}, ${row.district}`,
      sortable: true,
    },
    {
      name: 'Count',
      selector: (row) => row.count,
      width: '80px',
      sortable: true,
    },
    {
      name: 'Exp. Count',
      selector: (row) => row.expected_count,
      width: '80px',
      sortable: true,
    },

    {
      name: 'App Installed Count',
      selector: (row) => row.installed_count,
      width: '80px',
      sortable: true,
    },
    {
      name: 'Actions',
      button: true,
      left: true,
      cell: (row) => (
        <>
          <Button
            id={`button-${row._id}`}
            shape={'Round'}
            size={'Tiny'}
            appearance="outline"
            Status={'Warning'}
            onClick={() => {
              const newVillage = window.prompt(`Enter the new name of ${row['name']}`);
              if (newVillage) {
                moveVillage(row['name'], newVillage);
              }
            }}
            exact
            style={{ color: 'orange' }}
          >
            Edit
          </Button>
          <Link to={`/villages/verify?name=${row['name']}`}>
            <Button shape={'Round'} size={'Tiny'} Status={'Success'}>
              Verify
            </Button>
          </Link>
        </>
      ),
    },
  ];
  // console.log('google location', googleLocation);

  useEffect(() => {
    villagesList();
  }, []);

  const villagesList = async () => {
    const response = await getVillages();
    console.log('****response****', response);
    let max = 0;
    let max_install = 0;
    let total_count = 0;
    let total_expected_count = 0;
    let total_installed_count = 0;
    const data = response?.data.map((item, index) => {
      max = item.date_count[0]?.count > max ? item.date_count[0]?.count : max;
      max_install =
        item.date_count[0]?.installed_count > max_install ? item.date_count[0]?.installed_count : max_install;
      total_count = total_count + item.date_count[0]?.count;
      item?.verfication_info[0]?.[0]?.expected_count
        ? console.log('count:', item?.verfication_info[0]?.[0]?.expected_count, 'index :', index)
        : '';
      //here ternary operator does not give expected output inside total_exp_count (gives NaN)
      item?.verfication_info[0]?.[0]?.expected_count
        ? (total_expected_count = total_expected_count + item?.verfication_info[0]?.[0]?.expected_count)
        : '';
      total_installed_count = total_installed_count + item.date_count[0]?.installed_count;
      // console.log('item ', item);
      console.log('current Google Location', item?.verfication_info[0]?.[0]?.google_place_location?.formatted_address);
      return {
        seq: index + 1,
        name: item?.village,
        count: item.date_count[0]?.count,
        village_name: item?.verfication_info[0]?.[0]?.village_name ?? '',
        panchayat: item?.verfication_info[0]?.[0]?.ward_gram_panchayat ?? '',
        tehsil: item?.verfication_info[0]?.[0]?.panchayat_samiti_nagarapalika ?? '',
        district: item?.verfication_info[0]?.[0]?.district ?? '',
        latitude: item?.verfication_info[0]?.[0]?.latitude ?? '',
        longitude: item?.verfication_info[0]?.[0]?.longitude ?? '',
        installed_count: item?.date_count[0]?.installed_count,
        expected_count: item?.verfication_info[0]?.[0]?.expected_count,
        currentGoogleLocation: item?.verfication_info[0]?.[0]?.google_place_location?.formatted_address,
        pincode: item?.verfication_info[0]?.[0]?.pincode,
        google_map_url: item?.verfication_info[0]?.[0]?.google_map_url,
      };
    });
    setTotalCount(total_count);
    setTotalExpectedCount(total_expected_count);
    setTotalInstalledCount(total_installed_count);
    setMaxCount(max);
    setMaxInstallCount(max_install);
    console.log('villages list', data);
    setRows(data);
    setSearchRows(data);
    setOriginalRows(data);
    showLoader(false);
  };

  const handleInputChange = (event: any) => {
    do_search(event.target.value);
  };
  const do_search = (v) => {
    const value = v.trim();
    const fuzzyResult = fuse.search(value).map((item) => {
      return item.item;
    });
    if (value != '') {
      console.log('fuzzy Result', fuzzyResult);
      // let total_count = 0;
      // fuzzyResult.map((item) => {
      //   total_count = total_count + item.count;
      // })
      //setTotalCount(total_count);
      setRows(fuzzyResult);
    } else {
      // let total_count = 0;
      // searchRows.map((item) => {
      //   total_count = total_count + item.count;
      // })
      setTotalCount(total_count);
      setRows(searchRows);
    }
  };

  const filterAction = async (data) => {
    console.log('data to be filtered', data);
    const verifiedData = originalRows.filter((item) => {
      if (data.verified == 'ALL') {
        return item;
      }
      if (data.verified == 'VERIFIED') {
        return item.district != '';
      } else {
        return item.district == '';
      }
    });
    const filteredByDistrict = verifiedData.filter((item) => {
      if (data?.district) {
        return item.district == data.district;
      } else {
        return item;
      }
    });
    console.log('filtere data of district', filteredByDistrict);
    const filteredByTehsil = filteredByDistrict.filter((item) => {
      if (data?.tehsil) {
        return item.tehsil == data.tehsil;
      } else {
        return item;
      }
    });
    console.log('filtere data of tehsil', filteredByTehsil);
    const filterByPanchayat = filteredByTehsil.filter((item) => {
      if (data?.panchayat) {
        return item.panchayat == data.panchayat;
      } else {
        return item;
      }
    });
    console.log('filtere data of panchayat', filterByPanchayat);
    const filterByVillage = filterByPanchayat.filter((item) => {
      if (data?.village) {
        return item.village_name == data.village;
      } else {
        return item;
      }
    });
    console.log('filtere data', filterByVillage);

    const filterByLatLong = filterByVillage.filter((item) => {
      if (data.latLong == 'ALL') {
        return item;
      }
      if (data.latLong == 'LAT_LONG') {
        return item.latitude != '' || item.longitude != '';
      } else {
        return item.latitude == '' || item.longitude == '';
      }
    });
    // const filterByLatLong = filterByVillage.filter((item) => {
    //   return item.latitude == '' || item.longitude == ''
    // })

    console.log('filtere data by latlong', filterByLatLong);

    const filterByNodeCount = filterByLatLong.filter((item) => {
      return data.nodeCount[0] <= item.count && data.nodeCount[1] >= item.count;
    });
    console.log('filtere data by node count', filterByNodeCount);

    const filterByAppInstallCount = filterByNodeCount.filter((item) => {
      return data.appInstallCount[0] <= item.installed_count && data.appInstallCount[1] >= item.installed_count;
    });
    let total_count = 0;
    let install_count = 0;
    let total_expected_count = 0;
    filterByAppInstallCount.map((item) => {
      total_count = total_count + item.count;
      install_count = install_count + item.installed_count;
      total_expected_count = total_expected_count + item.expected_count;
    });
    setTotalCount(total_count);
    setTotalExpectedCount(total_expected_count);
    setTotalInstalledCount(install_count);
    console.log('filtere data by install count', filterByAppInstallCount);
    setRows(filterByAppInstallCount);
    setSearchRows(filterByAppInstallCount);
  };

  // const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;
  const actionsMemo = React.useMemo(() => <Export rows={rows} />, [rows]);
  // const actionsMemo = React.useMemo(() => <ExportCSV rows={rows} />, [rows]);
  const village = (
    <>
      {/* search will only be applied to "name" field only */}
      <div style={{ zIndex: '10' }}>
        {' '}
        <Filter filterAction={filterAction} max_node_count={max_node_count} max_install_count={max_install_count} />
      </div>
      <InputGroup fullWidth size="Small" status="Info">
        <input type="text" placeholder="Search..." onChange={(event) => handleInputChange(event)} />
      </InputGroup>
      <div style={{ margin: '10px' }}>
        <label htmlFor="google-places-filter">Google Location: </label>
        <select id="google-places-filter" value={googlePlacesFilter} onChange={handleGooglePlacesFilterChange}>
          <option value="ALL">ALL</option>
          <option value="YES">Yes</option>
          <option value="NO">No</option>
        </select>
      </div>
      <DataTable
        title="Villages"
        columns={tableColumns}
        data={rows}
        pagination
        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
        paginationPerPage={100}
        striped
        dense
        highlightOnHover
        actions={actionsMemo}
      />
      {editingRow && (
        <Popper
          disablePortal
          style={{ width: 600 }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="right-start"
        >
          <Paper elevation={3} style={{ padding: '10px', maxWidth: '450px' }}>
            <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>Existing : {editingRow.name}</div>
            <div style={{ marginTop: '10px', fontWeight: 'bold' }}>New Value :</div>
            <PlacesAutocomplete
              label="Location"
              type="googleLocation"
              placeAddressObject={{
                formatted_address: googleLocation?.formatted_address || editingRow?.['name'],
              }}
              setChange={true}
              setSelectedPlace={setGoogleLocation}
            />
            <div style={{ marginTop: '10px', textAlign: 'right' }}>
              <Button onClick={closePopper} style={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button onClick={confirmLocation}>Confirm</Button>
            </div>
          </Paper>
        </Popper>
      )}
      <Card>
        <CardBody>
          Total App Install Count : {totalInstalledCount}
          <br />
          Total Node Count : {totalCount}
          <br />
          Total Expected Node Count : {totalExpectedCount}
        </CardBody>
      </Card>
    </>
  );
  return <>{isLoading ? <LoadingSpinner message="Loading Data For Villages" /> : village}</>;
};
export default Villages;
