import React from 'react';
import PreviewCardHeader from '../componentUtils/previewCardHeader';
import { Card } from '@mui/material';
import './preview.css';

const PreviewEducation = ({ profile, isEditButton }) => {
  return (
    <Card>
      <div style={{ margin: '16px' }}>
        <PreviewCardHeader
          heading={'Education details'}
          stepValue={1}
          matrimonialId={profile?._id}
          isEditButton={isEditButton}
        />

        <div className="info-wrapper">
          <div className="info-values">
            <div className="label">Degree/Class</div>
            <div className="value">
              {profile?.education?.degree == '' ? 'Not Specified' : profile?.education?.degree}
            </div>
          </div>

          <div className="info-values">
            <div className="label">College/School</div>
            <div className="value">
              {profile?.education?.college == '' ? 'Not Specified' : profile?.education?.college}
            </div>
          </div>

          <div className="info-values">
            <div className="label">Field</div>
            <div className="value">{profile?.education?.field == '' ? 'Not Specified' : profile?.education?.field}</div>
          </div>

          <div className="info-values">
            <div className="label">Passing Year</div>
            <div className="value">
              {profile?.education?.passing_year == '' ? 'Not Specified' : profile?.education?.passing_year}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PreviewEducation;
