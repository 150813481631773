import React from 'react';
import { Card, CardContent, Typography, CardActions, Button, Box } from '@mui/material';

const ProjectCard = ({ project = {} }) => {
  const {
    name = '',
    location = {},
    status = '',
    summary = '',
    description = '',
    contributed_amount = 0,
    total_amount = 0,
  } = project;
  return (
    <Card sx={{ margin: 2, boxShadow: 3 }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <a
            href={`/projects/projectDetails?projectId=${project?._id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="project-link"
          >
            <Typography variant="h5" component="div">
              {name}
            </Typography>
          </a>
          <Typography sx={{ fontWeight: 'bold', color: status === 'IN PROGRESS' ? '#ff9800' : '#4caf50' }}>
            {status}
          </Typography>
        </Box>
        <Typography color="textSecondary">
          <strong>Location:</strong> {location?.formatted_address}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {summary}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {description}
        </Typography>
        <Typography variant="body1">
          <strong>Contributed Amount:</strong> ₹{contributed_amount?.toLocaleString('en-IN') || '0'}
        </Typography>
        <Typography variant="body1">
          <strong>Total Amount:</strong> ₹{total_amount?.toLocaleString('en-IN')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{
            borderRadius: '20px',
            marginLeft: '8px',
            padding: '8px 16px',
            textTransform: 'none',
          }}
        >
          Donate Now
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProjectCard;
