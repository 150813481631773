import React, { useEffect, useState } from 'react';
import { InputGroup } from '@paljs/ui/Input';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ExtractDataFromProfile } from './formatDisplayName';

const FilterAndSearchProfiles = ({ profiles, onFilteredProfilesChange }) => {
  const [filter, setFilter] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');

  const filterProfiles = () => {
    let filtered = profiles;

    // Apply search query filter
    const trimmedQuery = searchQuery.trim();
    if (trimmedQuery) {
      filtered = filtered.filter((profile) => {
        const { name } = ExtractDataFromProfile(profile); // Assuming profile has a `name` property
        return name?.toLowerCase().includes(trimmedQuery?.toLowerCase());
      });
    }

    // Apply dropdown filter
    if (filter === 'Male') {
      filtered = filtered.filter((profile) => profile.gender === 'male');
    } else if (filter === 'Female') {
      filtered = filtered.filter((profile) => profile.gender === 'female');
    } else if (filter === 'Verified') {
      filtered = filtered.filter((profile) => profile.graph_node_id);
    } else if (filter === 'Unverified') {
      filtered = filtered.filter((profile) => !profile.graph_node_id);
    }

    return filtered;
  };

  useEffect(() => {
    // Update filtered profiles in parent whenever filter or search query changes
    const filtered = filterProfiles();
    onFilteredProfilesChange(filtered);
  }, [filter, searchQuery, profiles]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      {/* Filter Dropdown */}
      <FormControl sx={{ minWidth: 150 }}>
        <InputLabel id="filter-select-label">Filter</InputLabel>
        <Select
          labelId="filter-select-label"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          label="Filter"
          sx={{ height: 36, width: '150px' }}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          <MenuItem value="Verified">Verified</MenuItem>
          <MenuItem value="Unverified">Unverified</MenuItem>
        </Select>
      </FormControl>

      {/* Search Input */}
      <div>
        <InputGroup fullWidth size="Small" status="Info" className="input-group-wrapper">
          <input
            type="text"
            placeholder="नाम से प्रोफाइल खोजे"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
            style={{
              width: '100%',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
        </InputGroup>
      </div>
    </div>
  );
};

export default FilterAndSearchProfiles;
