import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import '../styleCreateMatrimony.css';
import { AppContext } from '../Context';

export default function CreateMatrimonyCard(props: any) {
  const { member } = props;
  const { handleNextPage, handleChangeMultiValues } = useContext(AppContext);
  const handleCreate = async () => {
    //get
    // const response = await getInfoOfSelectedRelative()
    //
    console.log('clicked', member);
    const graph_node_id = member?.uid;
    // handleChangeMultiValues({
    //   graph_node_id: member?.uid,
    //   firstName: member?.name,
    //   gautra: member?.gautra,
    //   caste: member?.subcaste,
    //   gender: member?.gender,
    //   date: member?.born,
    // });
    //handleNextPage();
    const stepValue = 0;
    const url = `/matrimonials/stepComponents/stepForm?stepValue=${stepValue}&graph_node_id=${graph_node_id}`;
    window.open(url, '_blank');
  };
  let pic = 'blank.png';
  if (member?.pic_url) {
    pic = member.pic_url;
  }
  // console.log('item uid in createMatrimonyCard', member);
  return (
    <div className="frame-info-card">
      <div className="div-info-card">
        <img className="ellipse" alt="Ellipse" src={pic} />
        <div className="div-2">
          <a
            href={`/family-tree?idOfUser=${member?.uid}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-wrapper"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {member?.name}
          </a>

          <div className="text-wrapper-2">{member?.relation}</div>
        </div>
      </div>
      <div className="info-card-button">
        <Button
          // button="small"
          // buttonClassName="design-component-instance-node"
          // className="buttons-instance"
          // text="Added"
          variant="outlined"
          sx={{ borderRadius: '100px' }}
          onClick={handleCreate}
        >
          CREATE
        </Button>
      </div>
    </div>
  );
}
