import React, { useEffect, useState } from 'react';
import './projects.css';
import { Card, CardBody } from '@paljs/ui/Card';
import PlacesAutocomplete from '../extra-components/placesAutocomplete';
import Uploader from '../photos/uploadPhotos';
import moment from 'moment';
import { createProject, getProject, updateProject } from '../../actions/projectsAction';
import { toast } from 'react-toastify';
import data from '../../constantData.json';
import { useLocation } from '@reach/router';
// import Modal from '../../Utilities/Modal';

const CreateProjectModal = () => {
  const [newProject, setNewProject] = useState({
    name: null,
    location: {},
    summary: null,
    description: null,
    estimated_time: null,
    start_date: null,
    total_amount: null,
    contributed_amount: null,
    status: 'PLANNED',
    images: null,
  });

  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [project, setProject] = useState(null);
  const location = useLocation();

  const fetchProject = async (projectId) => {
    try {
      const result = await getProject(projectId);
      const { project } = result;
      setProject(project);
    } catch (error) {
      console.error('Error fetching project:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(decodeURI(location.search));
    const projectId = urlParams.get('projectId');
    if (projectId) {
      setIsEditMode(true);
      setLoading(true);
      fetchProject(projectId);
    }
  }, [location]);

  useEffect(() => {
    if (project) {
      const {
        name,
        summary,
        description,
        status,
        contributed_amount,
        total_amount,
        estimated_time,
        start_date,
        location,
        images = [],
      } = project;
      setNewProject({
        name,
        location,
        summary,
        description,
        estimated_time,
        start_date,
        total_amount,
        contributed_amount,
        status,
        images,
      });
    }
  }, [project]);

  const handleDateChange = (e) => {
    const selectedDate = moment(e.target.value).toDate();
    setNewProject((prevState) => ({
      ...prevState,
      start_date: selectedDate,
    }));
  };

  // Function to handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProject({
      ...newProject,
      [name]: value,
    });
  };

  // Function to handle form submission (for example purpose, logging data)
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(isEditMode ? 'Updating Project Details:' : 'New Project Details:', newProject);
    setLoading(true);

    try {
      const result = isEditMode
        ? await updateProject(project?._id, newProject) // Use the project ID for updates
        : await createProject(newProject);
      if (result && result?.status) {
        toast.success(isEditMode ? 'Project updated successfully!' : 'Project created successfully!');
        setNewProject({
          name: null,
          location: {},
          summary: null,
          description: null,
          estimated_time: null,
          start_date: null,
          total_amount: null,
          contributed_amount: null,
          status: 'PLANNED',
          images: null,
        });
        window.location.href = '/projects';
      } else {
        toast.error(result.message || (isEditMode ? 'Failed to update the project!' : 'Failed to create the project!'));
      }
    } catch (error) {
      toast.error('An error occurred while processing the project!');
    } finally {
      setLoading(false);
    }
  };

  return (
    // <Modal toggle={onClose}>
    <Card>
      <CardBody>
        <form onSubmit={handleSubmit} className="create-project-form">
          <h2>{isEditMode ? 'Update Project' : 'Create New Project'}</h2>
          <div className="form-group">
            <label>Project Name</label>
            <input
              type="text"
              className="input-class"
              name="name"
              value={newProject.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            {/* <label>Location</label> */}
            <PlacesAutocomplete
              type="projectLocation"
              label="Project Location"
              placeAddressObject={newProject.location}
              setSelectedPlace={(value) =>
                setNewProject({
                  ...newProject,
                  ['location']: value,
                })
              }
              setChange={true}
            />
            {/* <input type="text" name="location" value={newProject.location} onChange={handleChange} required /> */}
          </div>

          <div className="form-group">
            <label>Summary</label>
            <textarea
              name="summary"
              value={newProject.summary}
              onChange={handleChange}
              rows="5"
              className="textarea-field"
              placeholder="Enter a brief summary of the project"
              required
            />
          </div>

          <div className="form-group">
            <label>Description</label>
            <textarea
              name="description"
              value={newProject.description}
              onChange={handleChange}
              rows="5"
              className="textarea-field"
              placeholder="Enter a detailed description of the project"
              required
            />
          </div>

          <div className="form-group">
            <label>Estimated Time (in months)</label>
            <input
              type="text"
              name="estimated_time"
              className="input-class"
              value={newProject.estimated_time}
              onChange={handleChange}
              required
              aria-label="Estimated Time"
            />
          </div>

          <div className="form-group">
            <label>Start Date</label>
            <input
              className="input-class"
              type="date"
              value={newProject.start_date ? moment(newProject.start_date).format('YYYY-MM-DD') : ''}
              onChange={handleDateChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Total Amount</label>
            <input
              type="number"
              name="total_amount"
              className="input-class"
              value={newProject.total_amount}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Contributed Amount</label> {/* New field */}
            <input
              type="number"
              name="contributed_amount"
              className="input-class"
              value={newProject.contributed_amount}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Status</label> {/* Dropdown for project status */}
            <select name="status" value={newProject.status} onChange={handleChange} required>
              {data.PROJECT_STATUS.values.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.display}
                </option>
              ))}
            </select>
          </div>

          {/* <div className="form-group">
                        <label>Project Images</label>
                        <Uploader
                            // onUploadSuccess={(url, md5Code) =>
                            // addPhotoToSubevent(subevent.event_id, url, subevent._id, md5Code)
                            //   }
                            doCompression={false}
                            verifyDuplicateUpload={true}
                        />
                    </div> */}

          <button type="submit" className="submit-btn" disabled={loading}>
            {loading
              ? isEditMode
                ? 'Updating Project...'
                : 'Creating Project...'
              : isEditMode
              ? 'Update Project'
              : 'Create Project'}
          </button>
        </form>
      </CardBody>
    </Card>
    // </Modal>
  );
};

export default CreateProjectModal;
