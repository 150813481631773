import { getApiWrapper, postApiWrapper, deleteApiWrapper } from './token-wrapper-function';

export const getAllProjectsList = async () => {
    const result = await getApiWrapper(`/api/projects`);
    console.log('Result from getAllProjectsList', result);
    return result;
};

export const createProject = async (data) => {
    const result = await postApiWrapper(`/api/projects`, data);
    console.log('Result from createProject', result);
    return result;
};

export const updateProject = async (projectId, data) => {
    const result = await postApiWrapper(`/api/projects`, data);
    console.log('Result from updateProject', result);
    return result;
};

export const getProject = async (projectId) => {
    const result = await getApiWrapper(`/api/projects/${projectId}`);
    console.log('Result from getProject', result);
    return result;
};

export const createPaymentOrder = async (data, projectId) => {
    const result = await postApiWrapper(`/api/projects/${projectId}/order`, data);
    console.log('Result from createPaymentOrder', result);
    return result;
};

export const verifyPaymentOrder = async (data, projectId) => {
    const result = await postApiWrapper(`/api/projects/${projectId}/payment_verify`, data);
    console.log('Result from verifyPaymentOrder', result);
    return result;
};

export const createProjectPlanForSubscription = async (data, projectId) => {
    const result = await postApiWrapper(`/api/projects/${projectId}/subscription_plans`, data);
    console.log('Result from createProjectPlanForSubscription', result);
    return result;
};

export const createProjectSubscriptionForUser = async (data, projectId, graphNodeId) => {
    const result = await postApiWrapper(`/api/projects/${projectId}/${graphNodeId}subscriptions`, data);
    console.log('Result from createProjectSubscriptionForUser', result);
    return result;
};

export const verifySubscription = async (data, projectId) => {
    const result = await postApiWrapper(`/api/projects/${projectId}/subscription_verify`, data);
    console.log('Result from verifySubscription', result);
    return result;
};

  