import React, { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  CardActions,
} from '@mui/material'; // Material-UI components
import './projects.css'; // Import your CSS file
import { useLocation } from '@reach/router';
import { createPaymentOrder, getProject } from '../../actions/projectsAction';
import DonationModal from './DonationModal';
import { toast } from 'react-toastify';
import { getLoggedInUserId } from '../../actions/authAction';

const ProjectDetails = () => {
  const [filter, setFilter] = useState('All');
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [donationAmount, setDonationAmount] = useState('');
  const location = useLocation();
  const urlParams = new URLSearchParams(decodeURI(location.search));
  const projectId = urlParams.get('projectId');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDonateClick = () => {
    setModalOpen(true);
  };

  const handleDonationSubmit = async (donationDetails) => {
    const loggedInNodeId = getLoggedInUserId();
    const donationData = { graph_node_id: loggedInNodeId, amount: donationDetails?.amount };
    setModalOpen(false);
    try {
      const response = await createPaymentOrder(donationData, projectId);
      toast.success('Donation Successful!');
      console.log('Payment Order Response:', response);
    } catch (error) {
      console.error('Error creating payment order:', error);
      toast.error('Failed to process donation. Please try again.');
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const result = await getProject(projectId);
        const { project } = result;
        setProject(project);
      } catch (error) {
        console.error('Error fetching project:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId]);

  if (loading) {
    return (
      <Typography variant="h6" align="center">
        Loading...
      </Typography>
    );
  }

  if (!project) {
    return (
      <Typography variant="h6" align="center">
        Project not found
      </Typography>
    );
  }

  const {
    name,
    summary,
    description,
    status,
    contributed_amount,
    total_amount,
    location: { formatted_address },
    images = [],
  } = project;

  const handleEditClick = () => {
    window.location.href = `/projects/createProject?projectId=${projectId}`;
  };

  return (
    <Box className="project-page" p={2}>
      <Typography variant="h4" align="center">
        {name}
        {/* <Button onClick={handleEditClick}>Edit</Button> */}
      </Typography>
      <Card>
        <CardContent>
          {images.length > 0 ? (
            <img src={images[0]} alt={name} className="project-image" />
          ) : (
            <Typography>No image available</Typography>
          )}
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Location: {formatted_address}
          </Typography>
          <Typography sx={{ fontWeight: 'bold', color: status === 'IN_PROGRESS' ? '#ff9800' : '#4caf50' }} gutterBottom>
            <strong>Status:</strong> {status}
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            {summary}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Project Description
          </Typography>
          <Typography variant="body2" paragraph>
            {description}
          </Typography>
          <Typography variant="body1">
            <strong>Contributed Amount:</strong>{' '}
            {contributed_amount > 0 ? `₹${contributed_amount?.toLocaleString()}` : 'No amount contributed yet!'}
          </Typography>

          <Typography variant="body1">
            <strong>Total Amount Required:</strong> ₹{total_amount?.toLocaleString()}
          </Typography>
        </CardContent>
        {/* <CardActions>
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{
              borderRadius: '20px',
              marginLeft: '8px',
              padding: '8px 16px',
              textTransform: 'none',
            }}
            onClick={handleDonateClick}
          >
            Donate Now
          </Button>
        </CardActions> */}
      </Card>

      {modalOpen && <DonationModal onClose={handleModalClose} onSubmit={handleDonationSubmit} />}

      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel id="contributor-filter-label">Filter Contributors</InputLabel>
        <Select
          labelId="contributor-filter-label"
          id="contributor-filter"
          value={filter}
          onChange={handleFilterChange}
          label="Filter Contributors"
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Recurring">Recurring</MenuItem>
          <MenuItem value="One Time">One Time</MenuItem>
        </Select>
      </FormControl>

      <Box mt={1.5}>
        <Typography variant="h6">Contributors</Typography>
        <ul>
          {/* {contributors.map((contributor, index) => (
            <li key={index}>
              {contributor.name}: ₹{contributor.amount.toLocaleString()}
            </li>
          ))} */}
        </ul>
      </Box>
    </Box>
  );
};

export default ProjectDetails;
