import React, { useEffect, useState } from 'react';
import ProjectCard from './projectCard';
import './projects.css';
import { getAllProjectsList } from '../../actions/projectsAction';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch projects when the component mounts
  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      try {
        const result = await getAllProjectsList();
        if (result?.status) {
          setProjects(result.projects); // Assuming the response contains a 'data' field with project list
        } else {
          setError('Failed to load projects');
        }
      } catch (err) {
        setError('An error occurred while fetching projects');
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  // Function to navigate to the create project page
  const handleCreateProject = () => {
    window.location.href = '/projects/createProject';
  };

  return (
    <div className="projects-container">
      <div className="projects-header">
        <h2>LIST OF PROJECTS ({projects?.length})</h2>
        <button className="create-project-btn" onClick={handleCreateProject}>
          + Create New Project
        </button>
      </div>

      {loading ? (
        <p>Loading projects...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        projects?.map((project) => <ProjectCard key={project?._id} project={project} />)
      )}
    </div>
  );
};

export default Projects;
