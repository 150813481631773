import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import TextField from '@mui/material/TextField';
import { AppContext } from '../Context';
// import Autocomplete from '@mui/material/Autocomplete';
import './step-style-form.css';
import moment from 'moment';
import data from '../../../constantData.json';
import PlacesAutocomplete from '../../extra-components/placesAutocomplete';
import config from './config';
import dayjs from 'dayjs';
import { Box, Chip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PillBox from '../../../components/pills/PillBox';

// interface stateproperties {
//   name: string;
//   age: number;
// }

export default function FirstStep({ matrimonyData, setMatrimonyProfileData }) {
  // const { formValues, handleChange, handleNext, variant, margin, handleChangeMultiValues } = useContext(AppContext);

  // const [matrimonyData, setMatrimonyData] = useState(initialData || {});
  const maritalStatusOptions = data.MARITAL_STATUS.values;
  const manglikOptions = data.MAANGALIK.values;
  const bloodGroupOptions = data.BLOOD_GROUP.values;
  const complexionOptions = data.COMPLEXION.values;

  // const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
  // const [manglikOptions, setManglikOptions] = useState([]);
  // const {
  //   firstName,
  //   lastName,
  //   date,
  //   time,
  //   birthPlace,
  //   marriageStatus,
  //   homeAddress,
  //   aboutMe,
  //   email,
  //   manglik,
  //   height,
  //   bloodGroup,
  //   weight,
  //   color,
  //   gender,
  //   graph_node_id,
  //   hobbies,
  // } = formValues;

  const [newHobbies, setNewHobbies] = useState(matrimonyData?.hobbies || []);
  const updateHobbies = (updatedHobbies) => {
    setNewHobbies(updatedHobbies);
    handleChange({ target: { name: 'hobbies', value: updatedHobbies } });
  };

  // const isError = useCallback(
  //   () =>
  //     Object.keys({ firstName, lastName, email, gender }).some(
  //       (name) => (formValues[name].required && !formValues[name].value) || formValues[name].error,
  //     ),
  //   [formValues, firstName, lastName, email, gender],
  // );
  // useEffect(() => {
  //   if (initialData) {
  //     console.log('initialdata', initialData);
  //     // setMatrimonyData(initialData);
  //   }
  // }, [initialData]);

  const handleAutocomplete = (item: any, name: any) => {
    console.log(item);
    const event = {
      target: {
        value: item.target.lastChild.nodeValue,
        type: 'text',
        name: name,
      },
    };
    handleChange(event);
  };
  const handleBirthPlaceLocation = (place) => {
    setMatrimonyProfileData((prevData) => ({
      ...prevData,
      placeOfBirth: {
        ...place,
        formatted_address: place.formatted_address || place.place_name,
      },
    }));
  };
  const handleHomeAdressLocation = (place) => {
    setMatrimonyProfileData((prevData) => ({
      ...prevData,
      homeAddress: {
        ...place,
        formatted_address: place.formatted_address || place.street,
      },
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('selected name', name);
    setMatrimonyProfileData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      // setMatrimonyProfileData(updatedData); // Use updated data here
      return updatedData;
    });
  };

  const handleTimeChange = (time_selected: any) => {
    const timeString = `${moment(time_selected.$d).get('hour')}:${moment(time_selected.$d).get('minute')}`;
    const time_of_birth = moment(timeString, 'h:mm A').format('HH:mm'); // Convert to 24-hour format
    console.log('time of birth in 24-hour format: ', time_of_birth);
    setMatrimonyProfileData((prevData) => ({
      ...prevData,
      time: time_of_birth,
    }));
    // handleChangeMultiValues({ time: time_of_birth });
  };

  // useEffect(() => {
  //   fetch('/api/config_files/matrimonial_constants.json')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log('data from fetch ', data);
  //       if (data.MARITAL_STATUS) {
  //         setMaritalStatusOptions(data.MARITAL_STATUS.values);
  //       }
  //       if (data.MAANGALIK) {
  //         setManglikOptions(data.MAANGALIK.values);
  //       }
  //     })
  //     .catch((error) => console.error('Error fetching matrimonial constants:', error));
  // }, []);

  return (
    <div className="wrapper">
      <div className="step-header">Personal details</div>
      <div className="step-info">Note: You do not need to fill all, only fill what is relevant</div>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
        <Box sx={{ flex: '1 1 100%', padding: 1 }}>
          <TextField
            variant={'outlined'}
            margin={'normal'}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label="Date of birth"
            name="date"
            type="date"
            value={matrimonyData?.date ? moment(matrimonyData?.date).format('YYYY-MM-DD') : ''}
            // defaultValue={moment(date?.value).format('YYYY-MM-DD')}
            onChange={handleChange}
            // required={date.required}
          />
        </Box>
        <Box sx={{ flex: '1 1 100%', padding: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['MobileTimePicker']} sx={{ paddingTop: '16px' }}>
              <DemoItem>
                <MobileTimePicker
                  value={dayjs(matrimonyData?.time, 'HH:mm')}
                  label="Time"
                  onChange={(time_selected) => {
                    console.log('time_selected', time_selected);
                    handleTimeChange(time_selected);
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </Box>
        <Box sx={{ flex: '1 1 100%', padding: 1 }}>
          <PlacesAutocomplete
            type="birthPlace"
            label="Birth Place"
            placeAddressObject={{
              ...matrimonyData?.placeOfBirth,
              formatted_address:
                matrimonyData?.placeOfBirth?.formatted_address || matrimonyData?.placeOfBirth?.place_name,
            }}
            setSelectedPlace={handleBirthPlaceLocation}
            // setChange={true}
          />
        </Box>
        <Box sx={{ flex: '1 1 100%', padding: 1 }}>
          <PlacesAutocomplete
            type="homeAddress"
            label="Home Address"
            placeAddressObject={{
              ...matrimonyData?.homeAddress,
              formatted_address: matrimonyData?.homeAddress?.formatted_address || matrimonyData?.homeAddress?.street,
            }}
            setSelectedPlace={handleHomeAdressLocation}
          />
        </Box>
        <Box sx={{ flex: '1 1 100%', padding: 1 }}>
          <TextField
            variant={'outlined'}
            margin={'normal'}
            fullWidth
            select
            label="Marital Status"
            SelectProps={{
              native: true,
            }}
            name="marriageStatus"
            defaultValue="NEVER_MARRIED"
            value={matrimonyData?.marriageStatus}
            onChange={handleChange}
            // error={!!matrimonyData?.marriageStatus.error}
            //helperText={matrimonyData?.marriageStatus.error}
            // required={matrimonyData?.marriageStatus.required}
          >
            {maritalStatusOptions.map((option) => (
              <option key={option?.value} value={option?.value}>
                {option?.display}
              </option>
            ))}
          </TextField>
        </Box>
      </Box>

      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant='contained'
          sx={{ mt: 3, ml: 1 }}
          disabled={isError()}
          color='primary'
          onClick={!isError() ? handleNext : () => null}
        >
          Next
        </Button>
      </Box> */}

      <div className="step-header">Other details (Optional)</div>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
        <Box sx={{ width: { xs: '100%', sm: '100%' } }}>
          <TextField
            id="outlined-multiline-flexible"
            variant={'outlined'}
            margin={'normal'}
            fullWidth
            label="About me"
            name="aboutMe"
            multiline
            maxRows={4}
            minRows={3}
            placeholder="About me"
            // type='email'
            value={matrimonyData?.aboutMe}
            onChange={handleChange}
            // error={!!matrimonyData?.aboutMe.error}
            //helperText={matrimonyData?.aboutMe.error}
            // required={matrimonyData?.aboutMe.required}
          />
        </Box>

        {/* <Grid item xs={12} sm={12}>
          {/* <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true
            }}
            name='gautra'
            value={gautra.value}
            onChange={handleChange}
            error={!!gautra.error}
            helperText={gautra.error}
            required={gautra.required}
          >
            <option value='Unmarried'>Gautra</option>
            <option value='Married'>Married</option>
          </TextField> 
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={gautras.map((item) => {
              return item.name;
            })}
            onChange={(item) => {
              handleAutocomplete(item, 'gautra');
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Gautra" onChange={handleChange} value={gautra.value} />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            name="caste"
            label="caste"
            value={caste.value}
            onChange={handleChange}
            error={!!caste.error}
            defaultValue={'meghwal'}
            helperText={caste.error}
            required={caste.required}
          >
            <option value="meghwal">meghwal</option>
          </TextField>
        </Grid> */}

        {/* <Box sx={{ width: { xs: '100%', sm: '100%' } }}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            label="Manglik"
            SelectProps={{
              native: true,
            }}
            name="manglik"
            // defaultValue="false"
            value={matrimonyData?.manglik}
            onChange={handleChange}
            //  error={!!matrimonyData?.manglik.error}
            //helperText={matrimonyData?.manglik.error}
            required={matrimonyData?.manglik.required}
          >
            {manglikOptions.map((option) => (
              <option key={option?.value} value={option.value}>
                {' '}
                {option?.display}
              </option>
            ))}
          </TextField>
        </Box> */}

        {/* <Box sx={{ width: { xs: '100%', sm: '100%' } }}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Blood Group"
            name="bloodGroup"
            value={matrimonyData?.bloodGroup || ''}
            onChange={handleChange}
            // error={!!matrimonyData?.bloodGroup.error}
            // helperText={matrimonyData?.bloodGroup.error}
            //required={matrimonyData?.bloodGroup.required}
          >
            <option value=" ">---</option>
            {bloodGroupOptions.map((group, index) => (
              <option key={index} value={group}>
                {group}
              </option>
            ))}
          </TextField>
        </Box> */}
        {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={blood_groups}
            onChange={(item) => {
              handleAutocomplete(item, 'bloodGroup');
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Blood Group" onChange={handleChange} value={bloodGroup.value} />
            )}
          />
          </Grid> */}

        <Box sx={{ width: { xs: '100%', sm: '100%' } }}>
          <TextField
            variant={'outlined'}
            margin={'normal'}
            fullWidth
            label="Height (in cm)"
            name="height"
            placeholder="Your Height (in cm)"
            value={matrimonyData?.height || ''}
            onChange={(e) => {
              console.log('Height Change:', e.target.value);
              handleChange(e);
            }}
            //error={!!matrimonyData?.height.error}
            // helperText={height.error}
            // required={firstName.required}
          />
        </Box>

        <Box sx={{ width: { xs: '100%', sm: '100%' } }}>
          <TextField
            variant={'outlined'}
            margin={'normal'}
            fullWidth
            label="Weight (in Kg)"
            name="weight"
            placeholder="Your Weight (in Kg)"
            value={matrimonyData?.weight}
            onChange={handleChange}
            //error={!!weight.error}
            //  helperText={weight.error}
            // required={lastName.required}
          />
        </Box>

        {/* <Grid item xs={12} sm={6}> */}
        {/* <Box sx={{ width: '100%' }}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Color"
            name="color"
            value={matrimonyData?.color}
            onChange={handleChange}
            // error={!!color.error}
            //helperText={color.error}
            // required={color.required}
          >
            <option value=" ">---</option>
            {complexionOptions.map((complexion, index) => (
              <option key={index} value={complexion}>
                {complexion}
              </option>
            ))}
          </TextField>
        </Box> */}
        {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            componentName="color"
            options={complexions}
            sx={{ width: 300 }}
            onChange={(item) => {
              handleAutocomplete(item, 'color');
            }}
            renderInput={(params) => (
              <TextField {...params} name="color" label="Complexion" value={color.value} onChange={handleChange} />
            )}
          />
        </Grid> */}
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
          <PillBox heading="Add Hobbies" list={newHobbies} updatingData={updateHobbies} name="hobbies" />
        </Box>
      </Box>
    </div>
  );
}
