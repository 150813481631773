import React, { useEffect, useState } from 'react';
import { getVillageDataFromGoogle } from '../../actions/villageVerifyAction';
import { toast } from 'react-toastify';
import VillageGoogleMapInfo from './VillageGoogleMapInfo';
import LoadingSpinner from '../loader';
import UserGivenVillageDetails from './userGivenVillageDetails';

function VillageDetails({ name }) {
  const [loading, setLoading] = useState(false);
  const [villageDetails, setVillageDetails] = useState(null);
  const [villageGoogleMapInfo, setVillageGoogleMapInfo] = useState(null);
  const fetchVillageDetails = async () => {
    try {
      setLoading(true);
      const response = await getVillageDataFromGoogle(name);
      console.log('response from village details', response);
      if (response?.status) {
        const villageGoogleMapInfo = response?.data?.verified?.results?.[0];
        toast.success('Village Deatils Fetched Successfully');
        setVillageDetails(response?.data?.verified);
        setVillageGoogleMapInfo(villageGoogleMapInfo);
      }
    } catch (error) {
      toast.error('Failed to fetch village details');
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchVillageDetails();
  }, [name]);
  return (
    <div>
      {loading ? (
        <LoadingSpinner message={'Loading village details...'} />
      ) : villageDetails || villageGoogleMapInfo ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '20px',
            backgroundColor: 'whitesmoke',
            padding: '20px',
            borderRadius: '10px',
            marginBottom: '20px',
          }}
        >
          <UserGivenVillageDetails
            postUserGivenVillageDetails={() => fetchVillageDetails()}
            villageDetails={villageDetails}
          />
          <VillageGoogleMapInfo villageGoogleMapInfo={villageGoogleMapInfo} />
        </div>
      ) : (
        <p>No village details available.</p>
      )}
    </div>
  );
}

export default VillageDetails;
