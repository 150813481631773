import React, { useEffect, useState } from 'react';
import ExistingProfileCard from './componentUtils/existingProfileCard';
import { getAllMatrimonialProfiles } from '../../actions/matrimonialAction';
import { getLoggedInUserId } from '../../actions/authAction';
import MatrimonyPage from './matrimonyProfilesList';
import FilterProfiles from './componentUtils/filterAndSearchProfiles';
import LoadingSpinner from '../loader';
import { toast } from 'react-toastify';
import ProfilesList from './profilesList';

const showAllProfilesData = () => {
  const [allProfiles, setAllProfiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const loggedInUserId = getLoggedInUserId();
  const handleShowAllProfiles = async () => {
    try {
      setLoading(true);
      const result = await getAllMatrimonialProfiles();
      setLoading(false);
      if (result?.status) {
        toast.success(`All Matrimony profiles fetch succssfully`);
        setAllProfiles(result?.profiles);
      } else {
        toast.error(`Error: Failed to fetch All matrimonial profiles, ${result?.error_message}`);
        console.log('Error: Failed to fetch all matrimonial profiles');
      }
    } catch (error) {
      console.error('Error fetching all profiles:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleShowAllProfiles();
  }, [loggedInUserId]);
  return (
    <>
      <ProfilesList
        title={'पालीवाल समाज में बनाई गई सभी प्रोफाइल्स '}
        handleShowAllProfiles={handleShowAllProfiles}
        loading={loading}
        profiles={allProfiles}
      />
    </>
  );
};

export default showAllProfilesData;
