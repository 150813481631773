import React, { useContext, useEffect, useState } from 'react';
import { Card } from '@mui/material';
import PreviewCardHeader from '../componentUtils/previewCardHeader';
import { AppContext } from '../Context';
import { getMatrimonialProfileById } from '../../../actions/matrimonialAction';
import ViewPhotos from '../../photos/viewPhotos';
import './preview.css';
import LoadingSpinner from '../../loader';

const PreviewPhotoKundli = ({ matrimonialId: propMatrimonialId, isEditButton }) => {
  const { matrimonialId: contextMatrimonialId } = useContext(AppContext);
  const matrimonialId = propMatrimonialId || contextMatrimonialId;
  const [kundaliUrl, setKundaliUrl] = useState([]);
  const [photosUrls, setPhotosUrls] = useState([]);
  const [originalProfileUrls, setOriginalProfileUrls] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserProfileData();
  }, [matrimonialId]);

  const getUserProfileData = async () => {
    try {
      setLoading(true);
      const response = await getMatrimonialProfileById(matrimonialId);
      if (response.status) {
        const photoUrls = response?.profile?.photos?.map((photo) => photo.url) || [];
        const kundaliUrls = response?.profile?.kundali?.map((kundali) => kundali.url) || [];
        const originalProfileUrl =
          response?.profile?.original_profile?.map((originalProfile) => originalProfile.url) || [];

        setPhotosUrls(photoUrls);
        setKundaliUrl(kundaliUrls);
        setOriginalProfileUrls(originalProfileUrl);
        setLoading(false);
      } else {
        console.error('Error fetching user profile:', response);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  return (
    <Card className="preview-card">
      <div className="preview-content">
        <PreviewCardHeader
          heading={'Photos & Original Profile'}
          stepValue={3}
          matrimonialId={matrimonialId}
          isEditButton={isEditButton}
        />
        <div className="photo-kundli-container">
          <div className="photo-container">
            {loading ? (
              <LoadingSpinner message="Loading Photos" />
            ) : photosUrls.length > 0 ? (
              <ViewPhotos
                photos={photosUrls}
                heading="Photos"
                onClose={() => console.log('Close photos')}
                showCloseBtn={false}
                showDeleteBtn={false}
              />
            ) : (
              <div>No photos uploaded</div>
            )}
          </div>
          <div className="kundli-container">
            {loading ? (
              <LoadingSpinner message="Loading Original Profile Photos" />
            ) : originalProfileUrls.length > 0 ? (
              <ViewPhotos
                photos={originalProfileUrls}
                showFullScreen={true}
                heading="Original Profile"
                onClose={() => console.log('Close Original Profile')}
                showCloseBtn={false}
                showDeleteBtn={false}
              />
            ) : (
              <div>No Original Profile Uploaded</div>
            )}
          </div>
          {/* <div className="kundli-container">
            {loading ? (
              <LoadingSpinner message="Loading Kundli Photos" />
            ) : kundaliUrl.length > 0 ? (
              <ViewPhotos
                photos={kundaliUrl}
                heading="Kundali Photos"
                onClose={() => console.log('Close kundali')}
                showCloseBtn={false}
                showDeleteBtn={false}
              />
            ) : (
              <div>No Kundli uploaded</div>
            )}
          </div> */}
        </div>
      </div>
    </Card>
  );
};

export default PreviewPhotoKundli;
