import { getApiWrapper, postApiWrapper } from './token-wrapper-function.js';

export const getVillages = async () => {
  const result = await getApiWrapper(`/api/report/villages_users_data?`);
  return result;
};

export const updateVillage = async (data) => {
  const response = await postApiWrapper(`/api/village/edit`, data);
  return response;
};

export const getVillageUserInfo = async (name) => {
  const response = await getApiWrapper(`/api/users/village/${name}`);
  return response;
};

export const getVillagesList = async () => {
  const response = await getApiWrapper(`/api/users/list/villages`);
  return response;
};
