import React, { useState } from 'react';
import LoadingSpinner from '../loader';
import ExistingProfileCard from './componentUtils/existingProfileCard';
import { title } from 'process';
import FilterAndSearchProfiles from './componentUtils/filterAndSearchProfiles';

const ProfilesList = ({ handleShowAllProfiles, loading, profiles, title = '' }) => {
  const [filteredProfiles, setFilteredProfiles] = useState([profiles]);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <div style={{ marginBottom: '10px' }}>
            <h4>
              {title} ({filteredProfiles?.length})
            </h4>
          </div>
          <FilterAndSearchProfiles profiles={profiles} onFilteredProfilesChange={setFilteredProfiles} />
        </div>
        {loading ? (
          <LoadingSpinner message="Loading..." />
        ) : filteredProfiles?.length ? (
          <>
            {filteredProfiles?.map((profile, index) => (
              <ExistingProfileCard
                profile={profile}
                key={index}
                edit={true}
                postDeleteProfile={handleShowAllProfiles}
              />
            ))}
          </>
        ) : (
          'No Matrimony Profiles Created'
        )}
      </div>
    </div>
  );
};

export default ProfilesList;
